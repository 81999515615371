import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Processes from './components/Processes/index';
import Latest from './components/Latest/index';
import ContextMenuModal from '../../components/Modals/ContextMenuModal';
import Landing from '../Landing';
import Layout from '../../components/Layout';
import SearchField from '../../components/SearchField';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

const Home = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();
  
  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);

  const { handleSubmit, values, handleChange } = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      values.search = values.search.trim();
      const { search } = values;
      if (search[3] === '-' && search.length === 7) {
        history.push(`/tag/${search}`);
      } else {
        history.push(`/search?search=${search || ''}`);
      }
    },
  });

  const handleOpenScanner = () => {
    console.log('Scanner opened in Home');
    setIsScannerOpened(true);
  };

  const handleCloseScanner = () => {
    console.log('Scanner closed in Home');
    setIsScannerOpened(false);
  };

  if (!isLoading && !isAuthenticated) {
    return <Landing />;
  }

  return !isLoading && isAuthenticated ? (
    <Layout>
      <form onSubmit={handleSubmit} className="flex items-center justify-between">
        <SearchField
          name="search"
          value={values.search}
          onChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </form>
      <Processes
        isScannerOpened={isScannerOpened}
        handleScannerOpen={handleOpenScanner}
        handleScannerClose={handleCloseScanner}
      />
      <Latest />
      <ContextMenuModal />
    </Layout>
  ) : null;
};

export default React.memo(Home);

import React, { useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import FormField from 'src/components/FormField';
import { LocationValidation } from 'src/components/Modals/Location/components/LocationForm/locationValidation';
import OrganizationsSelect from 'src/components/OrganizationsSelect';
import { getXAuthOwnerHeader } from 'src/helpers/getXAuthOwnerHeader';
import SaveButton from 'src/components/SaveButton';
import CloseButton from 'src/components/CloseButton'; 
import ModalTitle from 'src/components/Modals/components/ModalTitle';
import FormWrapper from 'src/components/FormWrapper';
import { ILocationData } from 'src/store/services/locationApi/locationTypes';
import ModalContainer from 'src/components/Modals/components/ModalContainer';
import { useTranslation } from 'react-i18next';
import { ServerError } from 'src/store/storeTypes';
import ServerFormError from 'src/components/ServerFormError';
import { getLocationInitialValues, ILocationValues } from './getLocationInitialValues';

interface ILocationForm {
  title: string;
  data?: ILocationData;
  onSubmit: any;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  isOpened: boolean;
  onClose: () => void;
}

const LocationForm = ({
  title,
  isOpened,
  onClose,
  data,
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  error,
}: ILocationForm) => {
  const { t } = useTranslation('location');

  const initialValues = getLocationInitialValues(data);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    resetForm,
  }: FormikProps<ILocationValues> = useFormik<ILocationValues>({
    onSubmit: (values) => {
      const body = {
        name: values.name,
        description: values.description,
        address: {
          streetAddress: values.streetAddress,
          postalCode: values.postalCode,
          city: values.city,
          country: values.country,
        },
      };
      onSubmit({
        body,
        locationId: data?.id,
        ...getXAuthOwnerHeader(values.organization),
      });
      resetForm();
    },
    initialValues,
    validationSchema: LocationValidation(true),
  });

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <ModalContainer isModalOpened={isOpened} onClose={onClose} isFormModal={true}>
      <ModalTitle title={title} />
      <FormWrapper onSubmit={handleSubmit}>
        <FormField
          type="text"
          value={values.name}
          name={'name'}
          placeholder={t('name')}
          onChange={handleChange}
          isError={!!errors.name && !!touched.name}
          errorMessage={(touched.name && errors?.name) || ''}
        />
        <FormField
          as="textarea"
          type="text"
          value={values.description}
          name={'description'}
          placeholder={t('description')}
          onChange={handleChange}
          isError={!!errors.description && !!touched.description}
          errorMessage={(touched.description && errors?.description) || ''}
        />
        <FormField
          type="text"
          value={values.streetAddress}
          name={'streetAddress'}
          placeholder={t('streetAddress')}
          onChange={handleChange}
          isError={!!errors.streetAddress && !!touched.streetAddress}
          errorMessage={(touched.streetAddress && errors?.streetAddress) || ''}
        />
        <FormField
          type="text"
          value={values.postalCode}
          name={'postalCode'}
          placeholder={t('postalCode')}
          onChange={handleChange}
          isError={!!errors.postalCode && !!touched.postalCode}
          errorMessage={(touched.postalCode && errors?.postalCode) || ''}
        />
        <FormField
          type="text"
          value={values.city}
          name={'city'}
          placeholder={t('city')}
          onChange={handleChange}
          isError={!!errors.city && !!touched.city}
          errorMessage={(touched.city && errors?.city) || ''}
        />
        <FormField
          type="text"
          value={values.country}
          name={'country'}
          placeholder={t('country')}
          onChange={handleChange}
          isError={!!errors.country && !!touched.country}
          errorMessage={(touched.country && errors?.country) || ''}
        />

        <OrganizationsSelect
          setFieldValue={setFieldValue}
          value={values.organization}
          isError={!!errors.organization && !!touched.organization}
          errorMessage={(touched.organization && errors?.organization) || ''}
          placeholder={t('select-organization-placeholder')}
        />

        <div className="flex justify-center mt-4 mb-10">
          <SaveButton submitFunc={handleSubmit} disabled={isLoading} />
          <CloseButton onClose={onClose} disabled={isLoading} />
        </div>

        <ServerFormError
          title={t('error-request')}
          requestError={error as ServerError}
          isError={isError}
        />
      </FormWrapper>
    </ModalContainer>
  );
};

export default React.memo(LocationForm);

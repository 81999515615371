import React from 'react';
import { useLocation, useParams } from 'react-router';
import { useGetTagQuery } from 'src/store/services/tagsApi';
import useChangeAssetTheme from '../../hooks/useChangeAssetTheme';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'src/components/Loading';
import TagContent from 'src/views/Tag/components/TagContent';
import EmptyAsset from 'src/views/Tag/components/EmptyAsset';
import Layout from '../../components/Layout';

const Tag = () => {
  const { isAuthenticated } = useAuth0();
  const params = useParams<{ tag: string; externalSystem?: string }>();
  const location = useLocation();

  const { data, isSuccess, isLoading, isError } = useGetTagQuery({
    tag: params.tag,
    params: location.search,
    isTag: true,
  });

  useChangeAssetTheme(data);

  if (isLoading) {
    return <Loading />;
  }

  return isAuthenticated ? (
    <Layout>
      <div className="relative h-full w-full flex flex-col tag-layout-container">
        <div className="flex w-full  bg-card h-full">
          {isSuccess && <TagContent data={data} />}
          {isError && <EmptyAsset />}
        </div>
      </div>
    </Layout>
  ) : (
    <div className="relative h-full w-full flex flex-col tag-layout-container">
      <div className="flex w-full  bg-card h-full">
        {isSuccess && <TagContent data={data} />}
        {isError && <EmptyAsset />}
     </div>
    </div>
  );
};

export default React.memo(Tag);

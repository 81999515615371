import React, { ReactNode } from 'react';
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconClose } from '../../../assets/IconClose.svg';

interface IModalContainerProps {
  isModalOpened: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  isFormModal?: boolean;
}

const ModalContainer = ({
  children,
  isModalOpened,
  onClose,
  className,
  isFormModal = false,
}: IModalContainerProps) => {
  const { t } = useTranslation('tag');

  return (
    <Modal
      show={isModalOpened}
      onClose={onClose}
      className={`pb-[50px] bg-inherit !h-[100%] ${className}`}
    >
      {children}
      {!isFormModal && (
        <button
          type="button"
          onClick={onClose}
          className="absolute right-6 top-6 rounded-full bg-grey-asset"
        >
          <IconClose className="w-[1.6rem] h-[1.6rem] fill-white" />
        </button>
      )}
    </Modal>
  );
};

export default ModalContainer;

import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImportCSVToAssetMutation } from '../../../../store/services/assetsApi';
import { ReactComponent as IconQR } from 'src/assets/home/IconQR.svg';
import { ReactComponent as IconQR2 } from 'src/assets/home/IconQR2.svg';
import { ReactComponent as IconRegistration } from 'src/assets/home/IconRegistration.svg';
import { ReactComponent as IconUpload } from 'src/assets/home/IconUpload.svg';
import ProcessItem from './components/ProccessItem';
import { createFormDataFile } from 'src/helpers/createFormDataFile';
import Loading from 'src/components/Loading';
import AddBatchModal from '../../../../components/Modals/Batch/AddBatchModal';
import SerialAssetsModal from '../../../../components/Modals/SerialAssets/SerialAssetsModal';
import ScanButton from 'src/views/Home/components/ScanButton';

interface IProcessesProps {
  isScannerOpened: boolean;         
  handleScannerOpen: () => void;     
  handleScannerClose: () => void;   
}

const Processes = ({ isScannerOpened, handleScannerOpen, handleScannerClose }: IProcessesProps) => {
  const { t } = useTranslation('layout');
  const [importCSV, { isLoading: isCSVImporting }] = useImportCSVToAssetMutation();
  const importCSVInputRef = useRef<HTMLInputElement>(null);
  const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState<boolean>(false);
  const [isSerialAssetsModalOpen, setIsSerialAssetsModalOpen] = useState<boolean>(false);

  const onImportCSVProcessClick = () => {
    if (importCSVInputRef.current) {
      importCSVInputRef.current.click();
    }
  };

  const onImportCSVInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    createFormDataFile({
      documentType: 'DOCUMENT',
      onLoadFile: (formData: FormData) => importCSV(formData),
      event,
    });

  const handleBatchModalToggle = useCallback(() => {
    setIsCreateBatchModalOpen((prevState) => !prevState);
  }, []);

  const handleSerialAssetsModalToggle = useCallback(() => {
    setIsSerialAssetsModalOpen((prevState) => !prevState);
  }, []);

  const processItems = [
    {
      icon: IconQR,
      text: t('processes_scan-qr'),
      onClick: () => {
        if (isScannerOpened) {
          console.log('Closing scanner from Processes');  
          handleScannerClose();
        } else {
          console.log('Opening scanner from Processes');  
          handleScannerOpen();   
        }
      },
    },
    {
      icon: IconQR2,
      text: t('processes_print-labels'),
      onClick: () => handleBatchModalToggle(),
    },
    {
      icon: IconRegistration,
      text: t('processes_register-inventory'),
      onClick: () => handleSerialAssetsModalToggle(),
    },
    {
      icon: IconUpload,
      text: t('processes_import-csv'),
      onClick: () => onImportCSVProcessClick(),
    },
  ];

  if (isCSVImporting) {
    return <Loading />;
  }

  return (
    <div className="text-[#4C443C]">
      <h3 className="font-['Mukta_Mahee',_Arial,_sans-serif] font-bold text-lg leading-normal mt-[18px] mb-[18px]">
        {t('processes_processes-title')}
      </h3>
      <ul className="!p-5 border-8 border-solid border-homepage-processes-border-color rounded-[30px] text-current leading-[27px]">
        {processItems.map((item, index) => (
          <ProcessItem key={index} {...item} />
        ))}
      </ul>
      <input
        ref={importCSVInputRef}
        type="file"
        accept="text/csv"
        onChange={onImportCSVInputChange}
        className="hidden"
      />
      <AddBatchModal isOpened={isCreateBatchModalOpen} onClose={handleBatchModalToggle} />
      <SerialAssetsModal
        isModalOpen={isSerialAssetsModalOpen}
        onModalClose={handleSerialAssetsModalToggle}
      />
      {isScannerOpened && (
        <ScanButton
          isOpen={isScannerOpened}
          handleScannerOpen={handleScannerOpen}
          handleScannerClose={handleScannerClose}
        />
      )}
    </div>
  );
};

export default Processes;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { ReactComponent as IconHome } from 'src/assets/footer-menu/IconHome.svg';
import { ReactComponent as IconLayers } from 'src/assets/footer-menu/IconLayers.svg';
import { ReactComponent as IconLocation } from 'src/assets/footer-menu/IconLocation.svg';
import { ReactComponent as IconSearch } from 'src/assets/footer-menu/IconSearch.svg';
import { ReactComponent as IconPerson } from 'src/assets/footer-menu/IconPerson.svg';
import ButtonWithIcon from 'src/views/Landing/components/ButtonWithIcon';
import { ReactComponent as IconQR } from 'src/assets/home/IconQR.svg';
import logo from 'src/assets/footer-menu/images/sirk-tag-100.png';
import { themeConfigs } from '../constants/themeConfigs';

interface NavigationProps {
  isUserMenuOpen: boolean;
  handleUserMenuToggle: (position: 'top' | 'bottom') => void; 
  handleScannerOpen: () => void;
  currentTheme: string;
}

const Navigation: React.FC<NavigationProps> = ({
  isUserMenuOpen,
  handleUserMenuToggle,
  handleScannerOpen,
  currentTheme,
}) => {
  const { t } = useTranslation('layout');
  const location = useLocation();

  const activeColor = themeConfigs[currentTheme].bottomMenuActiveColor;
  const foregroundColor = themeConfigs[currentTheme].bottomMenuForegroundColor;

  const matchRoute = (route: string) => location.pathname === route;

  return (
    <>
      <nav className="fixed bottom-0 left-0 w-full text-white flex justify-around items-center h-[60px] pb-[10px] bg-bottom-menu-bg-color z-[80] md:hidden">
        <Link 
          to={ROUTES.HOME} 
          className="flex flex-col items-center justify-center leading-4" 
          style={{ color: foregroundColor }}
        >
          <IconHome 
            className="w-6 h-6" 
            style={{ fill: matchRoute(ROUTES.HOME) ? activeColor : foregroundColor }} 
          />
          {t('home')}
        </Link>
        <ButtonWithIcon
          className="flex flex-col items-center justify-center leading-4"
          icon={<IconQR className="w-6 h-6 fill-white" />}
          text={t('context-menu-scan-label', { ns: 'tag' })}
          onClick={handleScannerOpen}
        />
        <Link 
          to={ROUTES.SEARCH} 
          className="flex flex-col items-center justify-center leading-4" 
          style={{ color: foregroundColor }}
        >
          <IconSearch 
            className="w-6 h-6" 
            style={{ fill: matchRoute(ROUTES.SEARCH) ? activeColor : foregroundColor }} 
          />
          {t('bottom-nav-search')}
        </Link>
        <Link 
          to={ROUTES.BATCHES} 
          className="flex flex-col items-center justify-center leading-4" 
          style={{ color: foregroundColor }}
        >
          <IconLayers 
            className="w-6 h-6" 
            style={{ fill: matchRoute(ROUTES.BATCHES) ? activeColor : foregroundColor }} 
          />
          {t('batch')}
        </Link>
        <Link 
          to={ROUTES.LOCATION} 
          className="flex flex-col items-center justify-center leading-4" 
          style={{ color: foregroundColor }}
        >
          <IconLocation 
            className="w-6 h-6" 
            style={{ fill: matchRoute(ROUTES.LOCATION) ? activeColor : foregroundColor }} 
          />
          {t('location')}
        </Link>
        <button 
          type="button" 
          onClick={() => handleUserMenuToggle('bottom')} 
          className="flex flex-col items-center justify-center leading-4" 
          style={{ color: foregroundColor }}
        >
          <IconPerson className="w-6 h-6" style={{ fill: foregroundColor }} />
          {t('profile')}
        </button>
      </nav>

      <nav className="hidden md:flex flex-col justify-between items-center fixed h-full w-[130px] bg-bottom-menu-bg-color z-[80] left-0 py-2">
        <div className="flex flex-col items-center">
          <img src={logo} alt="SIRK:tek Logo" className="w-12 h-12 mb-2" />
        </div>
        <div className="flex flex-col w-full flex-grow justify-top space-y-4">
          <Link
            to={ROUTES.HOME}
            className="flex items-center h-[60px] w-full px-4 hover:bg-gray-700 transition"
            style={{ color: foregroundColor }}
          >
            <IconHome
              className="w-6 h-6"
              style={{ fill: matchRoute(ROUTES.HOME) ? activeColor : foregroundColor }}
            />
            <span className="ml-4 text-base font-medium">{t('home')}</span>
          </Link>
          <ButtonWithIcon
            className="flex items-center h-[60px] w-full px-4 text-white hover:bg-gray-700 transition space-x-4"
            icon={<IconQR className="w-6 h-6 fill-white" />}
            text={t('context-menu-scan-label', { ns: 'tag' })}
            onClick={handleScannerOpen}
          />
          <Link
            to={ROUTES.SEARCH}
            className="flex items-center h-[60px] w-full px-4 hover:bg-gray-700 transition"
            style={{ color: foregroundColor }}
          >
            <IconSearch
              className="w-6 h-6"
              style={{ fill: matchRoute(ROUTES.SEARCH) ? activeColor : foregroundColor }}
            />
            <span className="ml-4 text-base font-medium">{t('bottom-nav-search')}</span>
          </Link>
          <Link
            to={ROUTES.BATCHES}
            className="flex items-center h-[60px] w-full px-4 hover:bg-gray-700 transition"
            style={{ color: foregroundColor }}
          >
            <IconLayers
              className="w-6 h-6"
              style={{ fill: matchRoute(ROUTES.BATCHES) ? activeColor : foregroundColor }}
            />
            <span className="ml-4 text-base font-medium">{t('batch')}</span>
          </Link>
          <Link
            to={ROUTES.LOCATION}
            className="flex items-center h-[60px] w-full px-4 hover:bg-gray-700 transition"
            style={{ color: foregroundColor }}
          >
            <IconLocation
              className="w-6 h-6"
              style={{ fill: matchRoute(ROUTES.LOCATION) ? activeColor : foregroundColor }}
            />
            <span className="ml-4 text-base font-medium">{t('location')}</span>
          </Link>
          <button
            type="button"
            onClick={() => handleUserMenuToggle('top')}
            className="flex items-center h-[60px] w-full px-4 hover:bg-gray-700 transition"
            style={{ color: foregroundColor }}
          >
            <IconPerson className="w-6 h-6" style={{ fill: foregroundColor }} />
            <span className="ml-4 text-base font-medium">{t('profile')}</span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navigation;

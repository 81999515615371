import React, { useCallback, useRef } from 'react';
import { ReactComponent as IconLogout } from 'src/assets/footer-menu/IconLogout.svg';
import { useAuth0 } from '@auth0/auth0-react';
import useClickOutside from '../../../hooks/useClickOutSide/useClickOutside';

interface IUserMenu {
  onClose: () => void;
  position: 'top' | 'bottom'; 
}

const UserMenu: React.FC<IUserMenu> = ({ onClose, position }) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const userMenuRef = useRef<HTMLDivElement>(null);

  const logoutWithRedirect = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const handleAuth = useCallback(() => {
    isAuthenticated ? logoutWithRedirect() : loginWithRedirect();
  }, [isAuthenticated, loginWithRedirect, logoutWithRedirect]);

  useClickOutside(userMenuRef, onClose);

  return (
    <div ref={userMenuRef} className={`user-menu ${position}`}>
      <div
        onClick={handleAuth}
        className="menu-item"
      >
        <IconLogout className="w-6 h-6" />
        <p>{isAuthenticated ? 'Log Out' : 'Log In'}</p>
      </div>
      <hr className="menu-separator" />
      <div className="menu-item">
        {user?.email}
      </div>
    </div>
  );
};

export default UserMenu;

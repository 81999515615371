import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import UserMenu from './components/UserMenu';
import ModalContainer from '../../components/Modals/components/ModalContainer';
import Scanner from '../../components/Scanner/Scanner';
import Navigation from '../Navigation';

interface LayoutProps {
  children: React.ReactNode; 
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation('layout');
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [userMenuPosition, setUserMenuPosition] = useState<'top' | 'bottom'>('bottom'); 
  const location = useLocation();
  const currentTheme = process.env.REACT_APP_THEME || 'defaultTheme';
  const params = new URLSearchParams(location.search);
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(!!params.get('isOpenScanner'));

  const handleUserMenuToggle = useCallback((position: 'top' | 'bottom') => {
    setUserMenuPosition(position); 
    setIsUserMenuOpen((prev) => !prev);
  }, []);

  const handleScannerOpen = useCallback(() => setIsScannerOpen(true), []);
  const handleScannerClose = useCallback(() => setIsScannerOpen(false), []);

  return (
    <div className="relative h-full w-full flex flex-col layout-container">
      <div className="p-4 pb-[70px] h-full ">{children}</div>

      <Navigation
        isUserMenuOpen={isUserMenuOpen}
        handleUserMenuToggle={handleUserMenuToggle}
        handleScannerOpen={handleScannerOpen}
        currentTheme={currentTheme}
      />

      {isUserMenuOpen && <UserMenu onClose={() => handleUserMenuToggle(userMenuPosition)} position={userMenuPosition} />}
      <ModalContainer isModalOpened={isScannerOpen} onClose={handleScannerClose}>
        <Scanner />
      </ModalContainer>
    </div>
  );
};

export default Layout;

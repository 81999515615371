import React, { ReactNode } from 'react';
import { ReactComponent as IconExpand } from 'src/assets/IconExpand.svg';

interface ICustomAccordion {
  isAccordionOpen: boolean;
  setIsAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  accordionWrapperClassName?: string;
  contentWrapperClassName?: string;
  children: ReactNode;
  style?: React.CSSProperties;
}

const CustomAccordion = ({
  isAccordionOpen,
  setIsAccordionOpen,
  title,
  accordionWrapperClassName,
  contentWrapperClassName,
  children,
  style,  
}: ICustomAccordion) => {
  const toggleAccordion = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  return (
    <section
      id="accordion-collapse"
      data-accordion="collapse"
      className={`mt-[18px] ${accordionWrapperClassName}`}
      style={style} 
    >
      <button
        type="button"
        className="flex items-center justify-between w-full p-2 font-medium border border-gray-200 rounded-t-xl focus:ring-2 focus:ring-gray-200 hover:bg-gray-100 gap-3"
        data-accordion-target="#accordion-collapse-body-1"
        aria-expanded={isAccordionOpen}
        aria-controls="accordion-collapse-body-1"
        onClick={toggleAccordion}
      >
        <div className="flex justify-between items-center">
          <h2
            id="accordion-collapse-heading-1"
            className="text-[18.72px] font-bold"
          >
            {title}
          </h2>
        </div>
        <IconExpand className={`w-6 h-6 shrink-0 ${isAccordionOpen ? 'rotate-180' : ''}`} />
      </button>
      <div
        id="accordion-collapse-body-1"
        className={`${isAccordionOpen ? 'block' : 'hidden'}`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        {children && (
          <div
            className={`p-1 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 ${contentWrapperClassName}`}
          >
            {children}
          </div>
        )}
      </div>
    </section>
  );
};

export default CustomAccordion;

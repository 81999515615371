import React, { useEffect, useState } from 'react';
import { useGetLocationQuery } from '../../store/services/locationApi';
import Loading from '../../components/Loading';
import ScanButton from '../Home/components/ScanButton';
import LocationsTable from './components/LocationsTable/index';
import LocationsFilter from './components/LocationsTable/components/LocationsFilter';
import { ILocation } from '../../store/services/locationApi/locationTypes';
import ContextMenuModal from '../../components/Modals/ContextMenuModal';
import { useTranslation } from 'react-i18next'; 

const Location = () => {
  const { data, isLoading } = useGetLocationQuery({});
  const [filteredLocations, setFilteredLocations] = useState<ILocation[] | undefined>(undefined);
  const { t } = useTranslation(); 

  useEffect(() => {
    if (!isLoading) {
      setFilteredLocations(data);
    }
  }, [isLoading, data]);

  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);
  const handleOpenScanner = () => setIsScannerOpened(true);
  const handleCloseScanner = () => setIsScannerOpened(false);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="p-4 space-y-4">
      <div className="flex flex-col space-y-4">
        <LocationsFilter data={data} setLocations={setFilteredLocations} />
        <div className="flex items-center space-x-4">
          <ScanButton
            isOpen={isScannerOpened}
            handleScannerOpen={handleOpenScanner}
            handleScannerClose={handleCloseScanner}
            scannerMode="location"
            buttonText={t('scanner-location-button', { ns: 'tag' })}
            variant='location'
          />
        </div>
      </div>
      {filteredLocations && <LocationsTable data={filteredLocations} />}
      <ContextMenuModal />
    </div>
  );
};

export default React.memo(Location);
